import * as React from "react"
import { Link } from "gatsby"

const Wip = () => {
  return (
    <div>
      <h1>work in process</h1>
      <p>
        go to <Link to="/">home</Link> or <Link to="/writing">writing</Link>
      </p>
    </div>
  )
}

export default Wip
